import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import getStoreName from 'superstore/utils/getStoreName';
import { NAMESPACE_SUFFIX as OBJECT_STORE_NAME } from 'superstore/constants';
import Raven from 'raven-js';
export const QF_SEARCH_METRIC_NAMESPACE = 'crm-object-search-query-utilities';
export const QF_SEARCH_QF_CACHE_READ_HIT_METRIC = 'crm-object-search-query-utilities-qf-cache-hit';
export const QF_SEARCH_QF_CACHE_READ_MISS_METRIC = 'crm-object-search-query-utilities-qf-cache-miss';
export const QF_SEARCH_QF_CACHE_ERROR_METRIC = 'crm-object-search-query-utilities-qf-cache-read-error';
function getIdbValue({
  namespace,
  key
}) {
  return new Promise(resolve => {
    try {
      const openRequest = indexedDB.open(namespace);
      openRequest.onerror = () => {
        logErrorAsWarning(openRequest.error);
        resolve(null);
      };
      openRequest.onsuccess = () => {
        try {
          const db = openRequest.result;
          const getRequest = db.transaction(OBJECT_STORE_NAME, 'readonly').objectStore(OBJECT_STORE_NAME).get(key);
          getRequest.onsuccess = () => {
            resolve(getRequest.result);
          };
          getRequest.onerror = () => {
            logErrorAsWarning(getRequest.error);
            resolve(null);
          };
        } catch (error) {
          logErrorAsWarning(error);
          resolve(null);
        }
      };
    } catch (error) {
      logErrorAsWarning(error);
      resolve(null);
    }
  });
}
function logErrorAsWarning(error) {
  if (error instanceof Error) {
    Raven.captureException(error, {
      level: 'warning'
    });
    performance.mark(QF_SEARCH_QF_CACHE_ERROR_METRIC);
    console.error(error.message);
  }
}
export async function quickFetchCrmObjectsSearch({
  searchQueryVariablesLocalCacheKey
}) {
  const variables = await getIdbValue({
    namespace: getStoreName({
      namespace: QF_SEARCH_METRIC_NAMESPACE
    }),
    key: searchQueryVariablesLocalCacheKey
  });
  performance.mark(variables != null ? QF_SEARCH_QF_CACHE_READ_HIT_METRIC : QF_SEARCH_QF_CACHE_READ_MISS_METRIC);
  if (variables) {
    makeGqlEarlyRequest({
      url: quickFetch.getApiUrl('/graphql/crm'),
      operation: {
        operationName: 'CrmObjectsSearchQuery',
        // @ts-expect-error importGql is untyped.
        query: importGql('CrmObjectsSearchQuery'),
        variables
      }
    });
  }
}